import { Divider, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Paper>
        <Typography variant="h4" gutterBottom>
          Política de Privacidade
        </Typography>

        <Typography variant="body1">
          As informações sobre os resultados da loteria brasileira que são
          postadas nesse aplicativo são obtidas em outros sites, jornais, rede
          social e outros meios de comunicação, o objetivo do aplicativo é
          apenas agrupar essas informações em um único ponto.
        </Typography>

        <Typography variant="body1">
          Termos de uso sujeitos a atualização sem prévio aviso. Atualizado em
          Outubro/2023.
        </Typography>

        <Typography variant="h6">Importante</Typography>

        <Typography variant="body1">
          Ressaltamos a quem possa interessar que este aplicativo não arrecada
          ou promove quaisquer tipos de jogos ou apostas. Neste são analisados
          resultados amplamente divulgados pela imprensa e que são de
          conhecimento público. Esclarecemos que não temos qualquer tipo de
          ligação com quaisquer entes ou organizações que operem direta ou
          indiretamente apostas de quaisquer jogos, inclusive da loteria
          brasileira.
        </Typography>

        <Typography variant="body1">
          Este é apenas um aplicativo pessoal visando proporcionar análise de
          dados históricos, culturais e estatísticos de jogos institucionais ou
          não, e assim também a promoção da amizade e fraternidade humana.
        </Typography>

        <Typography variant="body1">
          Esclarecemos que ‘a loteria brasileira’ é uma prática contravencional
          e não induzimos ou recomendamos a ninguém que aposte ou exerça
          qualquer ato que lhe seja inerente.
        </Typography>

        <Typography variant="body1">
          O conteúdo deste aplicativo é exclusivo para maiores de 18 anos.
        </Typography>

        <Typography variant="h6">Segurança de Dados</Typography>

        <Typography variant="body1">
          Aqui estão mais informações que o desenvolvedor forneceu sobre os
          tipos de dados que este aplicativo pode coletar e compartilhar e as
          práticas de segurança que o aplicativo pode seguir. As práticas de
          dados podem variar com base na versão, uso, região e idade do seu
          aplicativo. Saber mais
        </Typography>

        <Typography variant="body1">
          Nenhum dado compartilhado com terceiros
        </Typography>

        <Typography variant="body1">
          O desenvolvedor afirma que este aplicativo não compartilha dados do
          usuário com outras empresas ou organizações. Saiba mais sobre como os
          desenvolvedores declaram o compartilhamento.
        </Typography>

        <Typography variant="body1">Dados coletados</Typography>

        <Typography variant="body1">
          Dados que este aplicativo pode coletar
        </Typography>

        <Typography variant="body1">Atividade do aplicativo</Typography>

        <Typography variant="body1">
          Interações de aplicativos, histórico de pesquisa no aplicativo,
          aplicativos instalados e outros conteúdos gerados pelo usuário
        </Typography>

        <Typography variant="body1">
          Informações e desempenho do aplicativo
        </Typography>

        <Typography variant="body1">
          Registros de falhas, diagnósticos e outros dados de desempenho do
          aplicativo
        </Typography>

        <Typography variant="body1">Dispositivo ou outros IDs</Typography>

        <Typography variant="body1">Dispositivo ou outros IDs</Typography>

        <Typography variant="body1">Práticas de segurança</Typography>

        <Typography variant="body1">
          Os dados são criptografados em trânsito
        </Typography>

        <Typography variant="body1">
          Seus dados são transferidos por uma conexão segura
        </Typography>

        <Typography variant="body1">
          Os dados não podem ser excluídos
        </Typography>

        <Typography variant="body1">
          O desenvolvedor não fornece uma maneira de solicitar que seus dados
          sejam excluídos
        </Typography>
        <Divider />
        <Typography variant="body2">
          Equipe dos APPs JCB Bicho RJ, Nacional, Look e SP agradece
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
